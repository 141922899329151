import { animate, state, style, transition, trigger } from '@angular/animations';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  inject,
  Input,
  OnChanges,
  output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

const expandAnimation = trigger('expandAnimation', [
  state('true', style({ height: '*', opacity: 1 })),
  state('false', style({ height: 0, opacity: 0 })),
  transition('true <=> false', animate('0.2s ease-in-out')),
]);

@UntilDestroy()
@Component({
  selector: 'cc-subnav-item',
  templateUrl: './subnav-item.component.html',
  styleUrls: ['./subnav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [expandAnimation],
  imports: [TooltipDirective, TranslateModule],
})
export class SubnavItemComponent implements AfterViewInit, OnChanges {
  @Input()
  title: string = '';

  @Input()
  iconClass: string | null = null;

  @Input()
  counter: number | string | null = null;

  @Input()
  selectable: boolean = true;

  @Input()
  selected: boolean = false;

  @Input()
  expanded: boolean = false;

  @Input()
  appearance: 'default' | 'tinted' = 'default';

  @Input()
  dropdownState: 'none' | 'open' | 'closed' = 'none';

  select = output<void>();
  headerClick = output<void>();

  @ContentChildren(SubnavItemComponent)
  inlineChildren: QueryList<SubnavItemComponent>;

  selected$ = new BehaviorSubject(false);

  dropdownVisible: boolean = false;

  protected depth: number = 0;

  protected readonly TooltipAlignment = TooltipAlignment;
  private readonly cdr = inject(ChangeDetectorRef);
  private subscriptions: Subscription[] = [];

  ngAfterViewInit(): void {
    this.initChildren();

    this.inlineChildren.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.initChildren();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.selected$.next(this.selected);
    }
  }

  protected handleHeaderClick(): void {
    this.headerClick.emit();

    if (!this.selectable) {
      this.expanded = !this.expanded;
      return;
    }

    this.select.emit();
  }

  protected handleToggleClick(event?: MouseEvent): void {
    event?.stopPropagation();
    this.expanded = !this.expanded;
  }

  private initChildren(): void {
    if (this.inlineChildren.length === 0) return;

    for (const subscription of this.subscriptions) subscription.unsubscribe();

    for (const child of this.inlineChildren) {
      child.depth = this.depth + 1;
      child.cdr.detectChanges();

      this.subscriptions.push(
        child.selected$.pipe(untilDestroyed(this)).subscribe((childSelected) => {
          if (childSelected) {
            this.selected$.next(true);
            this.expanded = true;
            this.cdr.detectChanges();
          }
        }),
      );
    }
  }
}
