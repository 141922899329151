<div
  [class.cc-overlay-active]="dropdownState === 'open'"
  [class.subnav-item--tinted]="appearance === 'tinted'"
  class="subnav-item"
>
  <div
    tabindex="0"
    (keydown.enter)="handleHeaderClick()"
    (click)="handleHeaderClick()"
    [class.subnav-item__header--selectable]="selectable"
    [class.subnav-item__header--selected]="selected"
    [style.--depth]="depth"
    class="subnav-item__header"
  >
    <div
      tabindex="0"
      (keydown.enter)="handleToggleClick()"
      (click)="handleToggleClick($event)"
      [ccTooltip]="expanded ? ('common.buttons.collapse' | translate) : ('common.buttons.expand' | translate)"
      [class.subnav-item__header-toggle--collapsed]="!expanded"
      [class.subnav-item__header-toggle--hidden]="!inlineChildren.length"
      [tooltipAlignment]="TooltipAlignment.Start"
      class="subnav-item__header-toggle"
    >
      <i class="icon-down-small-fill"></i>
    </div>

    <div class="subnav-item__header--content-wrapper">
      @if (iconClass) {
        <i
          [class]="iconClass"
          class="subnav-item__header-icon"
        ></i>
      } @else {
        <ng-content select="[slot='subnav-item-icon']"></ng-content>
      }

      <span class="subnav-item__header-title">
        {{ title }}
      </span>

      <ng-content select="[slot='subnav-item-action']"></ng-content>

      @if (counter) {
        <span class="subnav-item__header-counter">
          {{ counter }}
        </span>
      }

      @if (dropdownState !== 'none') {
        <span
          [class.subnav-item__header-dropdown-arrow--expanded]="dropdownState === 'open'"
          class="subnav-item__header-dropdown-arrow"
        >
          <i class="icon-chevronRight"></i>
        </span>
      }
    </div>
  </div>

  @if (inlineChildren.length && dropdownState === 'none') {
    <div
      [attr.inert]="!expanded ? '' : null"
      [attr.aria-hidden]="!expanded"
      [@expandAnimation]="expanded"
      [class.subnav-item__children--collapsed]="!expanded"
      class="subnav-item__children"
    >
      <ng-content select="cc-subnav-item"></ng-content>
    </div>
  }
</div>
