import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { CheckboxComponent } from '../../forms/checkbox/checkbox.component';

export interface ConfirmationDialogData {
  title?: string;
  description?: string;
  confirmActionText?: string;
  secondaryActionText?: string | null;
  cancelActionText?: string;
  destructive?: boolean;
  style: 'default' | 'compact';
  width?: string;
  rememberDecisionId?: string;
}

export interface ConfirmationDialogResult {
  decision: 'confirm' | 'secondaryAction' | 'cancel';
  rememberDecision: boolean;
}

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, CheckboxComponent],
})
export class ConfirmationDialogComponent {
  readonly dialogData: ConfirmationDialogData = inject(DIALOG_DATA);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly dialogRef: DialogRef<ConfirmationDialogResult> = inject(DialogRef);
  protected readonly rememberDecision = signal(false);

  handleDecision(decision: ConfirmationDialogResult['decision']): void {
    this.dialogRef.close({ decision, rememberDecision: decision !== 'cancel' ? this.rememberDecision() : false });
  }
}
