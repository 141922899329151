import { Dialog } from '@angular/cdk/dialog';
import type { Injector } from '@angular/core';
import { UserService } from '@clover/core/services/user.service';
import { lastValueFrom, map, take } from 'rxjs';
import {
  ConfirmationDialogComponent,
  type ConfirmationDialogData,
  type ConfirmationDialogResult,
} from './confirmation-dialog.component';

interface SavedDecision {
  actionId: string;
  decision: ConfirmationDialogResult['decision'];
  savedByUserId: number;
  savedAt: string; // Can be used later to invalidate saved decisions after a certain period of time
}

const STORAGE_KEY = 'clover:confirmationDialogSavedDecisions';

export async function presentConfirmationDialog(
  injector: Injector,
  data: ConfirmationDialogData,
): Promise<ConfirmationDialogResult['decision']> {
  if (data.rememberDecisionId) {
    const savedDecision = getSavedDecision(data.rememberDecisionId, injector);
    if (savedDecision) return savedDecision.decision;
  }

  const dialog = injector.get(Dialog);

  return await lastValueFrom(
    dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, { data }).closed.pipe(
      take(1),
      map((result) => {
        const { decision = 'cancel', rememberDecision = false } = result || {};
        if (rememberDecision) saveDecision(data.rememberDecisionId, decision, injector);

        return decision;
      }),
    ),
  );
}

function saveDecision(actionId: string, decision: ConfirmationDialogResult['decision'], injector: Injector): void {
  const userService = injector.get(UserService);

  const decisions = getDecisions(injector).filter((decision) => decision.actionId !== actionId);
  decisions.push({ actionId, decision, savedByUserId: userService.userProfile.id, savedAt: new Date().toISOString() });

  localStorage.setItem(STORAGE_KEY, JSON.stringify(decisions));
}

function getSavedDecision(actionId: string, injector: Injector): SavedDecision | undefined {
  return getDecisions(injector).find((decision) => decision.actionId === actionId);
}

function getDecisions(injector: Injector): SavedDecision[] {
  const userService = injector.get(UserService);

  const decisionsJSON = localStorage.getItem(STORAGE_KEY);
  const decisions = decisionsJSON ? JSON.parse(decisionsJSON) : [];

  return decisions.filter((decision) => decision.savedByUserId === userService.userProfile.id);
}
