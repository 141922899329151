import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { RouterLinkWithHref } from '@angular/router';
import { ChipComponent } from '@design/chips/chip/chip.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { type AssociatedCompany } from '@network/network/accounts/state/accounts.entities';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-company-card',
  standalone: true,
  imports: [CoreModule, RouterLinkWithHref, ChipComponent, NzButtonModule, TranslateModule],
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCardComponent {
  @Input()
  company: AssociatedCompany;

  get companyName(): string {
    return this.company.name;
  }

  get formattedLocation(): string | null {
    if (!this.company.address) return null;

    const location = this.company.address;
    const locationTokens = [location.streetAddressLine1, location.lastLine].filter((token) => !!token);

    if (locationTokens.length === 1) {
      return '';
    }
    return locationTokens.join(', ');
  }

  get companyIndustries(): string[] {
    if (!this.company.subIndustries) return [];
    return this.company.subIndustries.industries.slice(0, 3).map((industry) => industry.title);
  }

  get industryCount(): number {
    if (!this.company.subIndustries) return 0;
    return this.company.subIndustries.industries.length;
  }
}
