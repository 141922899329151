import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { ConnectionStatus } from '@clover/core/models/company';
import {
  getOffsetPagingOptionsParams,
  OffsetPagingOptions,
  PagingWrapper,
  type OffsetPagingWrapper,
} from '@core/helpers/paging';
import { HttpService } from '@core/services/http.service';
import { AssociatedCompany } from '@network/network/accounts/state/accounts.entities';
import { ICompanyList } from '@network/network/lists/lists.entities';
import { Observable, Subject } from 'rxjs';

export interface SearchCompaniesOptions {
  query: string;
  offset?: number;
  limit?: number;
  excludedIds?: number[];
  connectionStatuses?: Array<ConnectionStatus.Connected | ConnectionStatus.Invited> | null;
}

export interface ListCreationStatus {
  status: 'success' | 'error';
  listName: string;
  errorCode?: string;
}

export type ListsSortingProperty = 'name' | 'membersCount' | 'updatedAt';

@Injectable({
  providedIn: 'root',
})
export class CompanyListsService {
  private readonly _http = inject(HttpService);
  private readonly _router = inject(Router);
  private readonly _serializer = inject(UrlSerializer);

  private _listCreationStatus$ = new Subject<ListCreationStatus>();

  get listCreationStatus$(): Observable<ListCreationStatus> {
    return this._listCreationStatus$.asObservable();
  }

  sendListCreationStatusEvent(status: ListCreationStatus): void {
    this._listCreationStatus$.next(status);
  }

  getLists(
    query = '',
    pagingOptions?: OffsetPagingOptions<ListsSortingProperty>,
  ): Observable<PagingWrapper<ICompanyList>> {
    const urlTree = this._router.createUrlTree(['api', 'companiesLists', 'search'], {
      queryParams: {
        name: query,
        ...getOffsetPagingOptionsParams(pagingOptions),
      },
    });

    const path = this._serializer.serialize(urlTree);
    return this._http.getV2<PagingWrapper<ICompanyList>>(path);
  }

  // TODO (Oleksandr D.): limit the number of companies to be loaded at once, once we have the endpoint to return all company ids in the list
  getListCompanies(listId: number, offset = 0, limit = 999): Observable<PagingWrapper<AssociatedCompany>> {
    return this._http.getV2<PagingWrapper<AssociatedCompany>>(
      `api/companiesLists/${listId}/search?By=name&by=companies&order=asc&offset=${offset}&limit=${limit}`,
    );
  }

  addCompaniesToList(listId: number, companyIds: number[]): Observable<void> {
    return this._http.postV2<void>(`api/companiesLists/${listId}/addCompanies`, { companiesIds: companyIds });
  }

  removeCompaniesFromList(listId: number, companyIds: number[]): Observable<void> {
    return this._http.postV2<void>(`api/companiesLists/${listId}/deleteCompanies`, { companiesIds: companyIds });
  }

  createList(listName: string): Observable<ICompanyList> {
    return this._http.postV2<ICompanyList>('api/companiesLists', { name: listName });
  }

  renameList(listId: number, listName: string): Observable<void> {
    return this._http.postV2<void>(`api/companiesLists/${listId}/rename`, { newName: listName });
  }

  deleteList(listId: number): Observable<void> {
    return this._http.deleteV2(`api/companiesLists/${listId}`);
  }

  searchCompanies(options: SearchCompaniesOptions): Observable<OffsetPagingWrapper<AssociatedCompany>> {
    return this._http.postV2<OffsetPagingWrapper<AssociatedCompany>>(
      `api/companies/search?offset=${options.offset || 0}&limit=${options.limit || 20}`,
      {
        companiesIdsToExclude: options.excludedIds || [],
        name: options.query || '',
        includeOnlyConnected: options.connectionStatuses?.includes(ConnectionStatus.Connected) || undefined,
        includeDeferred: options.connectionStatuses?.includes(ConnectionStatus.Invited) || undefined,
      },
    );
  }
}
